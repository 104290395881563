import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./../context/AppContext";
import data from "./../utils/data";
import KYCLOGO from "./../assets/kyctitle.png";
import Options from "../components/Options";
import { db } from "../utils/firebaseConfig";
import { updateDoc, doc, serverTimestamp } from "firebase/firestore";
const Quiz = () => {
  let navigate = useNavigate();
  const { user, point, setCurrentQt, setQtLength, completeGame, setLoading } =
    useContext(AppContext);
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);
  useEffect(() => {
    if (completeGame) {
      window.location.reload();
    }
  }, [completeGame, navigate]);

  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  // const [score, setScore] = useState(0);
  const [nextquestion, setNextquestion] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const qts = data.sort(() => Math.random() - 0.5).slice(0, 5);
    // const qts = data;
    setQuestions(qts);
    setQtLength(qts.length);
  }, [setQtLength]);

  const gotoNext = async () => {
    const nextqt = currentQuestion + 1;
    if (nextqt < questions.length) {
      setCurrentQuestion(nextqt);
      setCurrentQt(nextqt);
      setIsSelected(false);
      setNextquestion(false);
    } else {
      console.log(gameOver);
      setGameOver(true);
      setLoading(true);
      const washingtonRef = doc(db, "users", user.id);
      await updateDoc(washingtonRef, {
        points: point,
        timestamp: serverTimestamp(),
      }).then(() => {
        setLoading(false);
        navigate("/result");
      });
    }
  };
  return (
    <div className="pageContainer">
      <div className="gecard">
        <img src={KYCLOGO} alt="know your contrast" className="kycLogo" />
        {/* <div className="">
          Question {currentQuestion + 1} /
          <span className=""> {questions.length}</span>
        </div> */}
        {questions.length > 0 && (
          <div className="">
            <div className="question">{questions[currentQuestion].title}</div>
            <Options
              options={questions[currentQuestion].options}
              answer={questions[currentQuestion].answer}
              setNextquestion={setNextquestion}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              // setScore={setScore}
              // score={score}
            />
            <div className="btn-group">
              <button
                onClick={gotoNext}
                className="btn"
                disabled={!nextquestion}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Quiz;
