import { useContext } from "react";
import { useMatch } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import GELOGO from "./../assets/gelogo.svg";
const Header = () => {
  const { currentQt, qtLength } = useContext(AppContext);
  const isQuiz = useMatch("/quiz");
  return (
    <header>
      <div className="container">
        {isQuiz && (
          <div className="scoreBg">
            Question: {currentQt + 1}/{qtLength}
          </div>
        )}

        <img src={GELOGO} alt="GE LOGO" className="gelogo" />
      </div>
    </header>
  );
};
export default Header;
