import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="pageContainer">
      <div className="gecard">
        <div className="notFound">404 - Page not found</div>
        <div className="btn-group">
          <Link to="/" className="btn">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
