const data = [
  {
    id: 1,
    title:
      "Contrast-induced Acute kidney injury (CI-AKI) is the third most common cause for acute kidney injury (AKI) in hospitalized patients?",
    options: ["True", "False"],
    answer: "True",
  },
  {
    id: 2,
    title:
      "Do you use a biomarker, if yes which biomarker do you use for identifying the high-risk patients?",
    options: [
      "Serum creatinine",
      "Glomerular filtration rate (GFR)",
      "Blood urea nitrogen (BUN)",
      "None",
    ],
    answer: "Glomerular filtration rate (GFR)",
  },
  {
    id: 3,
    title:
      "What is/are the risk stratification protocol/s for patients before contrast media administration?",
    options: [
      "RFT",
      "Hydration Status",
      "Both RFT & Hydration Status",
      "None of the above",
    ],
    answer: "Both RFT & Hydration Status",
  },
  {
    id: 4,
    title:
      "Which iodinated radiocontrast agent should you use for high-risk patients (DM,HTN,CKD,Age>60)?",
    options: [
      "Iodixanol/Isosmolar Contrast",
      "Low Osmolar Contrast",
      "High Osmolar Contrast",
      "Any of the above",
    ],
    answer: "Iodixanol/Isosmolar Contrast",
  },
  {
    id: 5,
    title: "What is the osmolality of Isosmolar/Iodixanol Contrast?",
    options: [
      "250 mOsm/kg H2O",
      "320 mOsm/kg H2O",
      "290 mOsm/kg H2O",
      "270 mOsm/kg H2O",
    ],
    answer: "290 mOsm/kg H2O",
  },
  {
    id: 6,
    title:
      "Isosmolar contrast (iodixanol) agent has the osmolality which is …………. to the osmolality of blood",
    options: [
      "Similar",
      "2-3 times higher",
      "5-8 times higher",
      "None of the above",
    ],
    answer: "Similar",
  },
  {
    id: 7,
    title:
      "Elevated creatinine levels after contrast administration can be an early sign of contrast-induced nephropathy (CIN), a condition that can cause kidney damage",
    options: ["True", "False"],
    answer: "True",
  },
  {
    id: 8,
    title: "Follow up Sr.Cr/eGFR measurement ideally should be performed after",
    options: ["within 24 hrs.", "48-72 hrs.", "After 1 week", "Not required"],
    answer: "48-72 hrs.",
  },
  {
    id: 9,
    title:
      "Strategies to consider before the Contrast Enhanced procedure to prevent CI-AKI",
    options: [
      "Adequate Hydration",
      "Withholding Nephrotoxic drugs",
      "Choice of contrast media (IOCM vs LOCM)",
      "Minimizing the contrast volume",
      "All of the above",
    ],
    answer: "All of the above",
  },
];

export default data;
