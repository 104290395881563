import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Header from "./Header";
import Loading from "./Loading";

const Layout = () => {
  const { loading } = useContext(AppContext);
  return (
    <>
      {loading && <Loading />}
      <Header />
      <Outlet />
    </>
  );
};
export default Layout;
