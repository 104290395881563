import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import KYCLOGO from "./../assets/kyctitle.png";
const LandingPage = () => {
  const { setCompleteGame } = useContext(AppContext);
  useEffect(() => {
    setCompleteGame(false);
  }, [setCompleteGame]);
  return (
    <div className="pageContainer">
      <div className="gecard">
        <img src={KYCLOGO} alt="know your contrast" className="kycLogo" />
        <ul className="instructions">
          <li>Attempt the 5 KYC MCQs.</li>
          {/* <li>
            Show your final score screen and collect your prize from the GE
            Stall.
          </li> */}
          <li>Once KYC completed, claim your Kit</li>
        </ul>
        <div className="btn-group">
          <Link to="login" className="btn">
            Enter
          </Link>
        </div>
      </div>
      <div className="appCode">JB00251BS/IN/02-23</div>
    </div>
  );
};
export default LandingPage;
