import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";

const Options = ({
  options,
  answer,
  setNextquestion,
  isSelected,
  setIsSelected,
}) => {
  const { point, setPoint } = useContext(AppContext);
  useEffect(() => {
    let allBtns = document.querySelectorAll("button");
    if (!isSelected) {
      allBtns.forEach((item) => {
        item.classList.remove("wrongAns");
      });
    }
  }, [isSelected]);
  const checkAns = (ans, e) => {
    if (ans !== answer) {
      e.target.classList.add("wrongAns");
      setNextquestion(true);
    } else {
      setNextquestion(true);
      setPoint(point + 1);
    }
    setIsSelected(true);
  };
  const letters = ["1", "2", "3", "4", "5"];
  return (
    <div className="allOptions">
      {options.map((item, index) => {
        return (
          <button
            key={index}
            onClick={(e) => checkAns(item, e)}
            className={`${isSelected && item === answer ? "correctAns" : ""}`}
            disabled={isSelected}
          >
            <span> {letters[index]}</span> {item}
          </button>
        );
      })}
    </div>
  );
};

export default Options;
