import { useContext, useEffect, useState } from "react";
import KYCLOGO from "./../assets/kyctitle.png";
import closeIcon from "./../assets/closeIcon.svg";
import { AppContext } from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../utils/firebaseConfig";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import santanceCase from "../utils/helper";

const LoginInfo = () => {
  const { user, setUser, setLoading } = useContext(AppContext);
  const [checkTerm, setCheckTerm] = useState(false);
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [nameInit, setNameInit] = useState("Mr.");
  const initialState = {
    name: "",
    hospitalName: "",
    city: "",
    mobile: "",
    email: "",
  };
  const [loginData, setLoginData] = useState(initialState);
  useEffect(() => {
    if (user) {
      navigate("/quiz");
    }
  }, [user, navigate]);

  const cansave = [
    loginData.name.trim().length > 0,
    loginData.hospitalName.trim().length > 0,
    loginData.city.trim().length > 0,
    loginData.email.trim().length > 0,
    checkTerm,
  ].every(Boolean);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (cansave) {
      // eslint-disable-next-line
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (reg.test(loginData.email) === false) {
        setLoading(false);
        toast.error("Invalid Email Address");
        return false;
      }
      const q = query(
        collection(db, "users"),
        where("email", "==", loginData.email)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setLoading(false);
        toast.error("This email id is already exists");
        return false;
      } else {
        try {
          const data = await addDoc(collection(db, "users"), {
            ...loginData,
            name: `${santanceCase(`${nameInit} ${loginData.name}`)}`,
            points: 0,
          });

          setUser({
            id: data.id,
            name: `${santanceCase(`${nameInit} ${loginData.name}`)}`,
          });
          setLoginData(initialState);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    }
  };
  const changeSalutation = (e) => {
    setNameInit(e.target.value);
  };
  return (
    <div className="pageContainer">
      {showModal && (
        <div className="modal">
          <div className="modalInner">
            <div className="closeBtn" onClick={() => setShowModal(false)}>
              <img src={closeIcon} alt="close" />
            </div>
            <h4>Terms and conditions.</h4>
            <ul className="termsUl">
              <li>
                <p>
                  By clicking here, you are agreeing to receive marketing
                  emails, newsletters and other promotional communications from
                  GE Healthcare from time to time.
                </p>
              </li>
              <li>
                <p>
                  You have a right to withdraw your consent at any time, by{" "}
                  <Link
                    to="https://landing1.gehealthcare.com/UnsubscribePage.html?mkt_unsubscribe=1"
                    target="_blank"
                  >
                    clicking here
                  </Link>
                  . We may still continue to send you service-related and other
                  non-promotional communications. For more information relating
                  to our privacy practices, we invite you to review our{" "}
                  <Link
                    to="https://www.gehealthcare.com/about/privacy/privacy-policy?showPopup=false"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="gecard">
        <img src={KYCLOGO} alt="know your contrast" className="kycLogo" />
        <form onSubmit={handleSubmit}>
          <div className="form-group salutationField">
            <label htmlFor="mr">
              <input
                type="radio"
                id="mr"
                name="salutation"
                defaultChecked
                value="Mr."
                onChange={changeSalutation}
              />{" "}
              Mr.
            </label>
            <label htmlFor="mrs">
              <input
                type="radio"
                id="mrs"
                name="salutation"
                value="Mrs."
                onChange={changeSalutation}
              />{" "}
              Mrs.
            </label>
            <label htmlFor="dr">
              <input
                type="radio"
                id="dr"
                name="salutation"
                value="Dr."
                onChange={changeSalutation}
              />{" "}
              Dr.
            </label>
          </div>
          <div className="form-group required">
            <div className="nameInit">{nameInit}</div>
            <input
              type="text"
              placeholder="Name"
              className="form-control"
              value={loginData.name}
              style={{ paddingLeft: "38px" }}
              onChange={(e) =>
                setLoginData({ ...loginData, name: e.target.value })
              }
            />
          </div>
          <div className="form-group required">
            <input
              type="text"
              placeholder="Hospital Name"
              className="form-control"
              value={loginData.hospitalName}
              onChange={(e) =>
                setLoginData({ ...loginData, hospitalName: e.target.value })
              }
            />
          </div>
          <div className="form-group required">
            <input
              type="text"
              placeholder="City"
              className="form-control"
              value={loginData.city}
              onChange={(e) =>
                setLoginData({ ...loginData, city: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Mobile"
              className="form-control"
              value={loginData.mobile}
              onChange={(e) =>
                setLoginData({ ...loginData, mobile: e.target.value })
              }
            />
          </div>
          <div className="form-group required">
            <input
              type="email"
              placeholder="E-mail"
              className="form-control"
              value={loginData.email}
              onChange={(e) =>
                setLoginData({ ...loginData, email: e.target.value })
              }
            />
          </div>
      
          <div className="form-group">
            <label htmlFor="termsCondition" className="checkBoxLabel">
              <input
                type="checkbox"
                id="termsCondition"
                onChange={() => setCheckTerm(!checkTerm)}
              />
              <p>
                I have read and agree to the{" "}
                <span
                  onClick={() => setShowModal(true)}
                  style={{ fontWeight: "bold" }}
                >
                  terms and conditions
                </span>
                .
              </p>
            </label>
          </div>
          <div className="btn-group">
            <button
              type="submit"
              to="login"
              className="btn"
              disabled={!cansave}
            >
              Enter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LoginInfo;
