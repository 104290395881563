import { db } from "../utils/firebaseConfig";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
const Admin = () => {
  const [users, setUsers] = useState([]);
  async function getData() {
    const querySnapshot = await getDocs(
      query(collection(db, "users"), orderBy("timestamp", "desc"))
    );
    let allData = [];
    querySnapshot.forEach((doc) => {
      allData.push(doc.data());
    });
    setUsers(allData);
  }
  useEffect(() => {
    getData();
  }, []);
  const downloadExcel = () => {
    var elt = document.getElementById("table");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "users" });
    XLSX.writeFile(
      wb,
      `knowyourcontrastdata-${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}.xlsx`
    );
  };
  useEffect(() => {
    document.body.classList.add("admin");
  }, []);

  return (
    <div className="adminPage container">
      {users.length > 0 ? (
        <>
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            <button className="btn" onClick={() => downloadExcel()}>
              Download As Excel
            </button>
          </div>
          <div className="tablePanel">
            <table id="table">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Name</th>
                  <th>City</th>
                  <th>Mobile</th>
                  <th>Hospital</th>
                  <th>Email</th>
                  <th>Points</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.city}</td>
                      <td>{item.mobile}</td>
                      <td>{item.hospitalName}</td>
                      <td>{item.email}</td>
                      <td>{item.points}</td>
                      <td>
                        {new Date(
                          item.timestamp.seconds * 1000
                        ).toLocaleDateString()}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        "No data found!"
      )}
    </div>
  );
};
export default Admin;
