import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import KYCLOGO from "./../assets/kyctitle.png";
// import GELOGO from "./../assets/gelogo.svg";
import thankImg from "./../assets/thankImg.svg";
// import WIN from "./../assets/win.svg";
// import LOSE from "./../assets/lose.svg";

const Result = () => {
  let navigate = useNavigate();
  const { user, setCompleteGame } = useContext(AppContext);
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);
  useEffect(() => {
    setCompleteGame(true);
  }, [setCompleteGame]);
  return (
    <div className="pageContainer">
      <div className="gecard">
        <img src={KYCLOGO} alt="know your contrast" className="kycLogo" />
        {/* <div className="finalPoints">
          {point}/{qtLength}
        </div> */}
        <p className="userName">{user?.name}</p>
        <p className="resultText">
          {/* {point > 3 ? (
            <img src={WIN} alt="win" />
          ) : (
            <img src={LOSE} alt="lose" />
          )} */}
          <img
            src={thankImg}
            alt="resultText"
            style={{ width: "80%", margin: "0 auto" }}
          />
        </p>
        {/* <div className="resultLogo">
          <img src={GELOGO} alt="GE LOGO" />
        </div> */}
        <div className="btn-group">
          <button className="btn" onClick={() => window.location.reload()}>
            Home
          </button>
        </div>
      </div>
    </div>
  );
};
export default Result;
