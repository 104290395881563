import { createContext, useState } from "react";

const AppContext = createContext();

const AppState = ({ children }) => {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [point, setPoint] = useState(0);
  const [currentQt, setCurrentQt] = useState(0);
  const [qtLength, setQtLength] = useState(0);
  const [completeGame, setCompleteGame] = useState(false);
  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        point,
        setPoint,
        currentQt,
        setCurrentQt,
        qtLength,
        setQtLength,
        loading,
        setLoading,
        completeGame,
        setCompleteGame,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppState };
