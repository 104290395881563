import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC1bcjoq7l4M3f_gSKOw7p6ITF_R54u9KM",
  authDomain: "knowyourcontrast.firebaseapp.com",
  projectId: "knowyourcontrast",
  storageBucket: "knowyourcontrast.appspot.com",
  messagingSenderId: "42093020939",
  appId: "1:42093020939:web:b464f9160ce066c38dc40e",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
